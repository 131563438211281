import React from "react";
import { Auth0Provider } from "@auth0/auth0-react";
import PrivateRoute from "../components/private-route";

const Layout = ({ children }) => {
  return <>{children}</>;
  return (
    <Auth0Provider
      domain={process.env.GATSBY_APP_AUTH0_DOMAIN}
      clientId={process.env.GATSBY_APP_AUTH0_CLIENTID}
      audience={process.env.GATSBY_APP_AUTH0_AUDIENCE}
      redirectUri={
        typeof window !== "undefined" && window.location.origin + "/"
      }
      useRefreshTokens={true}
      cacheLocation={"localstorage"}
      scope="openid read:current_user update:current_user_metadata"
      // onRedirectCallback={onRedirectCallback}
    >
      <PrivateRoute>{children}</PrivateRoute>
    </Auth0Provider>
  );
};

export default Layout;
