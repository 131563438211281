import React, { Component } from "react";
import AsideMenu from "./asidemenu";
import SearchBar from "./searchbar";
import MobileSelectMenu from "./mobileselectmenu";

import logo from "../../data/logo.svg";

class SideBar extends Component {
  render() {
    const { ast, logoUrl, footer, search, darkMode } = this.props;
    return (
      <div className={`sidebar ${darkMode ? "dark" : ""}`}>
        <img className="logo" src={logo} />
        {search && <SearchBar ast={ast} />}
        <AsideMenu ast={ast} />
        {/* <FooterLinks footer={footer}/> */}
        <MobileSelectMenu ast={ast} />
      </div>
    );
  }
}

export default SideBar;
